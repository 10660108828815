<template>
  <base-modal id="funnel-status">
    <h4>Edit - {{ locationInfo.name }}</h4>
    <div class="switch-buttons">
    <div class="buttons-flex">
      <div class="switch-row" style="flex-basis: 30%;">
        <p>Funnel Status</p>
        <InputSwitch v-model="funnelStatusChange" @input="funnelStatus" :disabled="$props.disable" />
      </div>
      <div class="switch-row" style="flex-basis: 60%;">
        <p>Snooze</p>
        <InputSwitch v-model="snoozeStatus" @input="updateStatus" />
      </div>
    </div>
     </div>

     <div class= "switch-row">
        <p>Threshold</p>
        <input type="number" v-model="thresholdValue" min="0" style="width: 40%;" @input="onChangeThreshold"/>
      </div>

    <div v-if="snoozeStatus">
      <p class="snooze-after">Snooze after</p>
      <div class="flex">
        <div class="radio-alignment">
          <input type="radio" id="oneWeek" class="snooze-radio" value="1" name="timer" @change="updateSnoozeTimer($event.target.value)">
          <label for="oneWeek" >One Week</label>
        </div>
        <div class="radio-alignment">
          <input type="radio" id="twoWeeks" class="snooze-radio" value="2" name="timer" @change="updateSnoozeTimer($event.target.value)">
          <label for="twoWeeks" >Two Weeks</label>
        </div>
      </div>
      <div class="flex">
        <div class="radio-alignment">
          <input type="radio" id="threeWeeks" class="snooze-radio" value="3" name="timer" @change="updateSnoozeTimer($event.target.value)">
          <label for="threeWeeks" >Three Weeks</label>
        </div>
        <div class="radio-alignment">
          <input type="radio" id="fourWeeks" class="snooze-radio" value="4" name="timer" @change="updateSnoozeTimer($event.target.value)">
          <label for="fourWeeks" >Four Weeks</label>
        </div>
      </div>
    </div>
    <div v-if="displayError" class="error-message">Any one timer needs to be selected</div>
    <div class="time-display-box" v-if="snoozeStatus && timer != ''">
      <div class="display-text">
        <!-- <p style="font-weight:550"> {{ snoozeStatus && timer != "" ? `${7*parseInt(this.timer)} Days remaining for snooze to turn off` : `Funnel Status is Switched ON` }} </p> -->
        <p>Snoozer turned on {{ displayCurrentDate }} <br> It will be automatically turned off after {{ calcTurnOffDate }} 23:59</p>
        <!-- <p v-else>Partners can apply for this location</p>  -->
      </div>
    </div>
    <div class="button-group button-group-funnelstatus">
      <button class="button" @click="save">Update</button>
      <button class="button secondary" @click="cancel" >Cancel</button>
    </div>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/modals/BaseModal";
import micromodal from "micromodal";
import { DateTime } from "luxon";
import InputSwitch from 'primevue/inputswitch';

export default {
  name: "FunnelStatusModal",
  components: { 
    BaseModal, 
    InputSwitch, 
  },
  props: {
    locationInfo: {
      type: Object,
      required: false,
      default: () => {}
    },
  },
  data() {
    return {
      snoozeStatus: false,
      timer: "",
      displayError: false,
      funnelStatusChange: false,
      thresholdValue: 0

    };
  },

  watch: {
    locationInfo(val){
      this.funnelStatusChange =  val.funnel.onboardingFunnelStatus
      this.thresholdValue = val.threshold 
    }
  },
  computed: {
    calcTurnOffDate() {
      return DateTime.utc().plus({ days: 7*parseInt(this.timer) - 1 }).toFormat("dd-MM-yyyy");
    },
    displayCurrentDate() {
      return DateTime.utc().toFormat('dd-MM-yyyy');
    }
},
  methods: {
    updateStatus(event) {
      this.snoozeStatus = event
      if (event == false) {
        this.timer = "";
        this.displayError = false;
      }
    },
  funnelStatus(val){
    this.funnelStatusChange = val
  },
    cancel() {
      this.$emit("cancel");
      this.closemodal();
    },
    closemodal() {
      this.snoozeStatus = false;
      this.timer = "";
      this.displayError = false;
      micromodal.close("funnel-status");
    },
    updateSnoozeTimer(data) {
      this.timer = data;
      this.displayError = false
    },
    onChangeThreshold(event) {
      this.thresholdValue = event.target.value >= 0 ? Math.floor(event.target.value) : 0;
    },
    save() {
      const valid = this.validate();
      if (valid) {
        this.snoozeStatus ?
          this.$emit("save", { 
            info: {
              snoozeTimer: this.timer, 
              snoozeStatus: this.snoozeStatus, 
              snoozeTurnedDate: DateTime.utc().toFormat("MM/dd/yyyy"),
              snoozeTurnOffDate: DateTime.utc().plus({ days: 7*parseInt(this.timer) - 1 }).toFormat("MM/dd/yyyy") 
            },
            locationId: this.$props.locationInfo.id,
            thresholdChange: this.thresholdValue,
            statusChange: this.funnelStatusChange
          })
          : this.$emit("save", { 
            locationId: this.$props.locationInfo.id, 
            thresholdChange: this.thresholdValue,
            statusChange: this.funnelStatusChange,  info: null });
        this.closemodal();
      }
    },
    validate() {
      if ((this.snoozeStatus && this.timer != "") || !this.snoozeStatus) {
        return true
      } else {
        this.displayError = true;
        return false
      }
    }
  }
};
</script>

<style scoped>
.snooze-after {
  margin-bottom: 1em;
  margin-top: 2.5em;
  font-weight: 550;
}
.snooze-radio {
  margin: 0 8px 0 0;
  width: 16px;
  height: 16px;
}
.radio-alignment {
  width: 260px;
  margin-bottom: 24px;
}
.time-display-box {
  background-color: #f4f4f4;
  height: 100px;
  margin: 48px -48px 40px -48px;
}
.display-text {
  width: calc(100% - 96px);
  margin-left: 48px;
}
p:not(:first-child) {
  font-size: 14px;
}
.button-group-funnelstatus {
  margin-top: 70px;
}
p{
  color: #707070;
}
label {
  color: #707070;
}
.buttons-flex{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

}

</style>