<template>
  <base-modal id="edit-zones" class="">
<h4>Edit Zones  </h4>
<data-table :headers="headers" 
    :entries="editedValues" 
    :useIndex = true
    table-id="editZonesTable"> 
    <template #location="{ item }">
      {{ item.name }}
    </template>
    <template #funnelStatus = "{item}" >
      <InputSwitch v-model="item.funnelStatus" v-if="getPermission('onOffLocations') " @change="changedStatus(item, 'status')"/>
    </template>
    <template #threshold="{ item }" >
      <div class="flex">
        <input type="number" v-if="getPermission('addThreshold') || getPermission('editThreshold')" v-model="item.threshold" min="0" @change="changedStatus(item, 'threshold')">
      </div>
    </template>
  </data-table>
    <div class="button-group">
      <button class="button primary" @click="updateData">Save</button>
      <button class="button secondary" @click="closeModal">Cancel</button>
    </div>
  </base-modal>
</template>
<script>
import BaseModal from "@/components/modals/BaseModal";
import { DataTable, TableHeader } from "@/components";
import micromodal from "micromodal";
import { mapGetters } from "vuex";
import InputSwitch from 'primevue/inputswitch';

export default {
  name: "EditZonesModal",
  components: { 
    BaseModal, 
    DataTable,
    InputSwitch
  },
  props: {
    editZones: {
      type: Array,
      required: false,
      default: () => []
    },
  },

  data() {
    return {
      funnelStatusChange: false,
      thresholdValue: 0,
      editedValues: []
    };
  },
  computed: {
    ...mapGetters(["getUsername", "getPermission"]),

    headers() {
    return [
      new TableHeader({ name: "location", label: "Zone", cellContents: "name", headerClass: "cell20", sortable: true }),
      new TableHeader({ label: "Status", headerClass: "cell10", cellContents: "funnelStatus", name: "funnelStatus", sortable: false }),
      new TableHeader({ label: "Threshold", headerClass: "cell10", cellContents: "threshold", name: "threshold", sortable: false }),
    
    ];
   }
  },
  watch:{
    editZones(val){
        this.editedValues = val
    }
  },
  methods: {
  changedStatus(val, type){
    if(type === 'status'){
      this.editedValues[val.index].funnelStatus = val.funnelStatus
    }
    else if(type === 'threshold'){
      let item = {...this.editedValues[val.index],threshold: val.threshold >= 0 ? Math.floor(val.threshold) : 0}
      this.$set(this.editedValues, val.index, item);
    }
},
   updateData() {
      this.$emit("modified", this.editedValues)
      micromodal.close("edit-zones");

    },
    closeModal() {
      micromodal.close("edit-zones");
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.response-msgs .failed {
  color: brown;
}
.response-msgs {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
}
.upload-file {
  margin-top: 48px;
}
.response-msgs .success {
  color: seagreen;
}
.pad-left-90 {
  padding-left: 90%;
}
.margin-left-60 {
  margin-left: 60px;
}
.loader-class {
  color: white;
}
</style>
